/* eslint-disable no-undef */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import "./src/styles/global.scss"
import config from "./mason-config.json"
import { AuthEnabledContext } from "./src/AuthContext"

const { domain, organization_id: organizationId, auth = "False" } = config

const isAuthEnabled = auth === "True"

const onRedirectCallback = appState => navigate(appState?.returnTo || "/")


export const wrapRootElement = ({ element }) => {
  const wrappedElement = React.cloneElement(element, {
    authEnabled: isAuthEnabled,
  })

  return (
    <AuthEnabledContext.Provider value={isAuthEnabled}>
      <Auth0Provider
        domain={`${domain}.us.auth0.com`}
        clientId={process.env.GATSBY_CLIENT_ID}
        redirectUri={window.location.origin}
        organization={organizationId}
        onRedirectCallback={onRedirectCallback}
      >
        {wrappedElement}
      </Auth0Provider>
    </AuthEnabledContext.Provider>
  )
}
